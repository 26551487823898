<template>
  <b-sidebar
    id="add-change-password-sidebar"
    :visible="isAddChangePasswordSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-change-password-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Change Password</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <!-- <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">Old Password</label>
             
            </div>
            <validation-provider
              #default="{ errors }"
              name="Old Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="Password"
                  v-model="userdata.old_password"
                  :type="OldpasswordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 6 ? false : null"
                  name="login-password"
                  placeholder="Old Password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconOldPassword"
                    @click="toggleOldPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">Password</label>
              <!-- <b-link :to="{ name: 'auth-forgot-password-v1' }">
                <small>Forgot Password?</small>
              </b-link> -->
            </div>
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="Password"
                  v-model="userdata.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  @input="passwordFunction"
                  name="login-password"
                  placeholder="Password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ PasswordMessageAlert }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">Confirm Password</label>
              <!-- <b-link :to="{ name: 'auth-forgot-password-v1' }">
                <small>Forgot Password?</small>
              </b-link> -->
            </div>
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="Password"
                  v-model="userdata.confirm_password"
                  :type="ConfirmpasswordFieldType"
                  class="form-control-merge"
                  @input="confirmpasswordFunction"
                  name="login-password"
                  placeholder="Confirm Password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcondata"
                    @click="toggleConfirmPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{
                confirmPasswordMessageAlert
              }}</small>
            </validation-provider>
          </b-form-group>

          <!-- <div
            class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              style="margin-left: 10px"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div> -->
        </b-form>
      </validation-observer>
    </template>
    <template #footer="{ hide }">
      <div class="d-flex bg-light text-light align-items-center px-1 py-2">
        <!-- <div > -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="onSubmit"
          :disabled="submitbutton"
        >
          Update
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="ml-2"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          Cancel
        </b-button>
      </div>
      <!-- </div> -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BLink,
  BInputGroup,
  BFormFile,
  BFormSelect,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import {
  togglePasswordVisibility,
  toggleConfirmPasswordVisibility,
  toggleOldPasswordVisibility,
} from "@core/mixins/ui/forms";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import stringify from "uuid/dist/stringify";
export default {
  components: {
    BSidebar,
    BCol,
    BLink,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddChangePasswordSidebarActive",
    event: "update:is-add-change-password-sidebar-active",
  },
  props: {
    isAddChangePasswordSidebarActive: {
      type: Boolean,
      required: true,
    },
    changePasswordId: {
      type: String,
      required: true,
    },
  },
  mixins: [
    togglePasswordVisibility,
    toggleConfirmPasswordVisibility,
    toggleOldPasswordVisibility,
  ],
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      userdata: {
        password: "",
        confirm_password: "",
      },
      add_new_level_data: "",
      PasswordMessageAlert: "",
      confirmPasswordMessageAlert: "",
    };
  },
  // created() {
  //   console.log(this.addlevelclassid);
  // },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIcondata() {
      return this.ConfirmpasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    submitbutton() {
      return !(
        this.userdata.password.length > 5 &&
        this.userdata.confirm_password.length > 5
      );
    },
  },
  methods: {
    passwordFunction() {
      // console.log("this.userdata.password.length");
      if (this.userdata.password.length >= 6) {
        this.PasswordMessageAlert = "";
      } else {
        this.PasswordMessageAlert = "Password must be atleast 6 characters";
      }
      if (this.userdata.password.length == 0) {
        this.PasswordMessageAlert = "The Password field is required";
      }
    },
    confirmpasswordFunction() {
      // console.log("this.userdata.password.length");
      if (this.userdata.password_confirmation.length >= 6) {
        this.confirmPasswordMessageAlert = "";
      } else {
        this.confirmPasswordMessageAlert =
          "Confirm Password must be atleast 6 characters";
      }
      if (this.userdata.password_confirmation.length == 0) {
        this.confirmPasswordMessageAlert =
          "The Confirm Password field is required";
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.userdata = {
        old_password: "",
        password: "",
        confirm_password: "",
      };
    },
    onSubmit() {
      // alert(0)
      if (this.userdata.password == this.userdata.confirm_password) {
        this.userdata.id = this.changePasswordId;
        this.add_new_user_data = this.userdata;
        this.$emit("clickaddChangePasswordUser", this.add_new_user_data);
        this.$root.$emit("bv::toggle::collapse", "add-change-password-sidebar");
      } else {
        this.showToast("danger");
        // alert("Please confirm your password first");
      }
    },

    showToas(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "form  submit",
          variant,
        },
      });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Confirm password not match",
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
